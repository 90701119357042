const formMixins = {
  methods: {
    classForField({ passed, failed, loading }) {
      if (passed && !loading) {
        return 'is-success'
      } else if (failed && !loading) {
        return 'is-danger'
      } else {
        return ''
      }
    }
  }
}
export default formMixins
