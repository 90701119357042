<template>
  <a @click.prevent="doAction" :disabled="isDisabled" :class="{ 'is-loading': loading, 'box-shadow': hasBoxShadow && !isDisabled }" href="javascript: void(0)">
    <slot />
  </a>
</template>

<script>
export default {
  props: ['action', 'disabled', 'hasBoxShadow'],
  data: () => {
    return { loading: false }
  },
  computed: {
    isDisabled() {
      return this.loading || this.disabled
    }
  },
  methods: {
    click() {
      this.doAction()
    },
    async doAction() {
      if (this.isDisabled) {
        return false
      }
      this.loading = true
      await this.action()
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
a {
  color: #fff !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
.box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
}
</style>
