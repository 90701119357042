import { getEnvironment } from './getEnvironment'

const initializePendo = (id, email) => {
  if (getEnvironment() !== 'production') return
  /* eslint-disable-next-line */
  pendo.initialize({
    visitor: {
      id,
      email,
    },
  })
}

export {
  initializePendo
}
